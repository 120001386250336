<template>
  <div>
    <div style="margin: 0 0 10px 20px;">
      <span style="margin-right: 10px" class="deptCode">所属部门:</span>
      <Cascader
        v-model="formData.addArchiveUserRo.deptCode"
        @on-change="selectDepartment"
        style="width:300px;display:inline-block"
        :data="departmentList"
        filterable
        change-on-select
      ></Cascader>
      <span style="margin:0 10px" class="deptCode">主责社工:</span>
      <Select
        v-model="formData.addArchiveUserRo.staffId"
        style="width: 300px; margin-right: 20px"
        placeholder="请先选择所属部门,再选择部门下的员工"
      >
        <Option
          v-for="item in socialWorkerList"
          :value="item.value"
          :key="item.value"
        >{{ item.label }}</Option>
      </Select>
    </div>
    <!-- 主体内容 -->
    <div class="container">
      <!-- 左侧菜单 -->
      <Menu theme="light" :active-name="selectMenuName" @on-select="selectMenu" class="menu">
        <MenuGroup title="菜单栏">
          <MenuItem name="AddChangeInfo">服务对象基本资料</MenuItem>
          <MenuItem name="AddChangeClassification">分级分类情况</MenuItem>
          <MenuItem name="AddChangeNeed">案主问题及需求</MenuItem>
          <MenuItem name="AddChangeWorker">社工评估及处理跟进工作</MenuItem>
        </MenuGroup>
      </Menu>
      <div class="rightContent">
        <keep-alive>
          <component
            :is="componentName"
            :baseInfo.sync="formData.baseInfo"
            :addArchiveUserRo="formData.addArchiveUserRo"
            :needData="formData.addArchiveQuestionRos"
            :worker="formData.worker"
            :orgCodeList="orgCodeList"
            :identification="identification"
            :formData="formData"
          ></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import AddChangeInfo from "@/views/archives/childrens/addChangeChildrens/addChangeInfo";
import AddChangeNeed from "@/views/archives/childrens/addChangeChildrens/addChangeNeed";
import AddChangeWorker from "@/views/archives/childrens/addChangeChildrens/addChangeWorker";
import AddChangeClassification from "@/views/archives/childrens/addChangeChildrens/addChangeClassification";
import { format } from "@/utils/formatTime";
export default {
  props: ["identification", "info", "userId"],
  data() {
    return {
      formData: {
        mainWork: "", //主责社工
        department: "", //所属部门
        baseInfo: {
          //服务对象基本资料
          orgName: "", //其他社区
          other: false, //所属其他
          addUserNeedyRo: {}, //困难户基本信息
          addUserContactsRo: {}, //紧急联系人
          addUserDetailRo: {}, //居民详情信息
          addUserHouseRo: {}, //用户居住情况
          addUserHealthRo: {}, //用户健康情况
          addUserIncomeRo: {}, //用户收入情况
          socialServiceContent: "", // 社会服务
          mobile: "" //手机号
        },
        addArchiveUserRo: {}, //分级分类情况
        addArchiveQuestionRos: {
          //案主问题及需求
          个体层面: {},
          家庭层面: {},
          社会层面: {}
        },
        worker: {
          //社工评估
          addArchiveFollowRo: {}, //处理跟进工作
          addArchiveAssessRo: {} //社工评估
        },
        existingServiceContent: "" //现有服务
      },
      socialWorkerList: [], //主责社工
      departmentList: [], //所属部门列表
      selectMenuName: "AddChangeInfo", //菜单选中
      componentName: "AddChangeInfo",
      deptCodeList: [], //修改回显时部门选中列表
      orgCodeList: [] //社区列表
    };
  },
  methods: {
    // 选择部门，加载社工
    selectDepartment(code) {
      this.socialWorkerList = [];
      //获取主责社工列表
      this.$get("/datamsg/api/pc/staff/selectDeptUserByDeptCode", {
        deptCode: code[code.length - 1],
        oemCode: parent.vue.oemInfo.oemCode
      }).then(res => {
        if (res) {
          this.socialWorkerList = res.map(item => {
            return {
              value: item.custGlobalId,
              label: item.name
            };
          });
        }
      });
    },
    // 获取案主问题及需求的字段名数据
    getQuestionNeed() {
      this.$get("/archives/api/pc/archiveQuestionConfig/queryAll").then(res => {
        if (res.code == 200 && res.dataList) {
          res.dataList.map(item => {
            if (item.topType == 1) {
              if (
                !this.formData.addArchiveQuestionRos["个体层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["个体层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["个体层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: ""
              });
            } else if (item.topType == 2) {
              if (
                !this.formData.addArchiveQuestionRos["家庭层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["家庭层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["家庭层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: ""
              });
            } else {
              if (
                !this.formData.addArchiveQuestionRos["社会层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["社会层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["社会层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: ""
              });
            }
          });
        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    //选择菜单
    selectMenu(name) {
      this.componentName = name;
    },
    //保存提交数据
    save() {
      // 检验所属部门
      if (
        !this.formData.addArchiveUserRo.deptCode ||
        this.formData.addArchiveUserRo.deptCode == "" ||
        this.formData.addArchiveUserRo.deptCode == []
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "请选择所属部门"
        });
        return;
      }
      // 检验主责社工
      if (
        !this.formData.addArchiveUserRo.staffId ||
        this.formData.addArchiveUserRo.staffId == ""
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "请选择主责社工"
        });
        return;
      }
      // 获取社区名称addArchiveUserRo.orgCode
      if (this.formData.addArchiveUserRo.orgCode) {
        this.orgCodeList.map(item => {
          if (item.value == this.formData.addArchiveUserRo.orgCode) {
            this.formData.baseInfo.orgName = item.label;
          }
        });
      }
      // 检验所属社区
      if (
        !(
          (this.formData.addArchiveUserRo.orgCode &&
            this.formData.baseInfo.orgName) ||
          this.formData.addArchiveUserRo.orgName
        )
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "服务对象基本资料：所属社区不能为空"
        });
        return;
      }
      //校验姓名
      if (
        this.formData.baseInfo.addUserNeedyRo.name == "" ||
        this.formData.baseInfo.addUserNeedyRo.name == undefined
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "服务对象基本资料：姓名不能为空"
        });
        return;
      }
      //校验性别和手机号
      if (
        this.formData.baseInfo.addUserNeedyRo.sex == "" ||
        this.formData.baseInfo.addUserNeedyRo.sex == undefined
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "服务对象基本资料：性别不能为空"
        });
        return;
      }

      if (
        this.formData.baseInfo.mobile ==
          this.formData.baseInfo.addUserContactsRo.mobile &&
        this.formData.baseInfo.mobile != undefined &&
        this.formData.baseInfo.mobile != ""
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "服务对象基本资料：联系电话和紧急联系电话不能相同"
        });
        return;
      }

      if (
        this.formData.addArchiveUserRo.difficultType == "" ||
        this.formData.addArchiveUserRo.difficultType == undefined
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "分级分类情况：分类不能为空"
        });
        return;
      }
      if (
        this.formData.addArchiveUserRo.archiveNum == "" ||
        this.formData.addArchiveUserRo.archiveNum == undefined
      ) {
        this.$Message.error({
          background: true,
          duration: 3,
          content: "分级分类情况：档案编号不能为空"
        });
        return;
      }

      this.$Message.loading("正在提交数据，请稍等...");
      //提取案主问题及需求的数据
      let arr = [];
      for (let k in this.formData.addArchiveQuestionRos) {
        for (let i in this.formData.addArchiveQuestionRos[k]) {
          this.formData.addArchiveQuestionRos[k][i].map(item => {
            arr.push({
              questionId: item.questionId,
              questionResult: item.questionResult
            });
          });
        }
      }
      // 获取主责社工名称
      this.socialWorkerList.map(item => {
        if (item.value == this.formData.addArchiveUserRo.staffId) {
          this.formData.addArchiveUserRo.staffName = item.label;
        }
      });
      let url = "";
      let params = {
        addArchiveUserRo: {
          ...this.formData.addArchiveUserRo,
          deptCode: this.formData.addArchiveUserRo.deptCode
            ? this.formData.addArchiveUserRo.deptCode[
                this.formData.addArchiveUserRo.deptCode.length - 1
              ]
            : "",
          oemCode: parent.vue.oemInfo.oemCode,
          orgName: this.formData.addArchiveUserRo.orgCode
            ? this.formData.baseInfo.orgName
            : this.formData.addArchiveUserRo.orgName,
          orgCode: this.formData.addArchiveUserRo.orgCode
        },
        addUserNeedyRo: {
          ...this.formData.baseInfo.addUserNeedyRo,
          birthday: format(
            this.formData.baseInfo.addUserNeedyRo.birthday,
            "yyyy-MM-dd"
          ),
          oemCode: parent.vue.oemInfo.oemCode
        },
        addUserContactsRo: {
          ...this.formData.baseInfo.addUserContactsRo
        },
        addUserDetailRo: {
          ...this.formData.baseInfo.addUserDetailRo
        },
        addUserHouseRo: {
          ...this.formData.baseInfo.addUserHouseRo
        },
        addUserHealthRo: {
          ...this.formData.baseInfo.addUserHealthRo
        },
        addUserIncomeRo: {
          ...this.formData.baseInfo.addUserIncomeRo
        },
        addArchiveQuestionRos: arr,
        addArchiveAssessRo: {
          ...this.formData.worker.addArchiveAssessRo
        },
        addArchiveFollowRo: {
          ...this.formData.worker.addArchiveFollowRo
        },
        existingServiceContent: this.formData.existingServiceContent,
        socialServiceContent: this.formData.baseInfo.socialServiceContent,
        mobile: this.formData.baseInfo.mobile,
        staffName: parent.vue.loginInfo.userinfo.realName,
        staffId: parent.vue.loginInfo.userinfo.custGlobalId
      };
      if (this.identification) {
        //代表修改
        url = "/archives/api/pc/archiveUser/updateQuestion";
        params.archiveId = this.info;
        params.userId = this.userId;
      } else {
        url = "/archives/api/pc/userNeedy/addQuestion";
      }

      this.$post(url, params, { "Content-Type": "application/json" })
        .then(res => {
          this.$Message.destroy();
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功"
            });
            this.$emit("addChangeStatusFn");
            this.$emit("hadlePageSize", {
              page: 1,
              pageSize: 20
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "保存失败"
          });
        });
    },
    // 递归构建部门树
    getTreeFn(data, index) {
      return data.map(item => {
        return {
          value: item.code,
          label: item.title,
          children: (() => {
            if (item.children && item.children.length > 0 && item.children[0]) {
              return this.getTreeFn(item.children);
            } else {
              return [];
            }
          })()
        };
      });
    },
    //修改回显时查找部门树
    getdeptCode(list) {
      list.map(item => {
        if (
          JSON.stringify(item).indexOf(
            this.formData.addArchiveUserRo.deptCode[0]
          ) != -1
        ) {
          this.deptCodeList.push(item.value);
          if (
            item.children &&
            item.children.length > 0 &&
            item.children[0] &&
            item.value != this.formData.addArchiveUserRo.deptCode[0]
          ) {
            this.getdeptCode(item.children);
          } else {
            this.formData.addArchiveUserRo.deptCode = this.deptCodeList;
            this.selectDepartment(this.formData.addArchiveUserRo.deptCode);
          }
        }
      });
    }
  },

  async created() {
    this.$Message.loading({
      content: "正在加载数据...",
      duration: 0
    });
    if (!this.identification) {
      this.getQuestionNeed();
    }
    //获取所属部门
    await this.$get("/gx/pc/staff/selectUserPermissionDeptTree", {
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if (res.code == 200 && res.dataList) {
        this.departmentList = this.getTreeFn(res.dataList);
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    });

    //获取所属社区
    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
      staffId: parent.vue.loginInfo.userinfo.id,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.orgCodeList = res.dataList.map(item => {
          return {
            label: item.fullName,
            value: item.code
          };
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "获取社区列表失败！请联系管理员"
        });
      }
    });

    //修改读取数据
    if (this.identification) {
      await this.$get("/archives/api/pc/archiveUser/updateQuestionDetail", {
        archiveId: this.info
      }).then(res => {
        if (res.code == 200 && res.data) {
          this.formData.addArchiveUserRo = {
            ...res.data.archiveDetailVo,
            deptCode: [res.data.archiveDetailVo.deptCode],
            orgName: res.data.archiveDetailVo.orgCode
              ? ""
              : res.data.archiveDetailVo.orgName
          };
          this.getdeptCode(this.departmentList);

          this.formData.baseInfo.addUserNeedyRo = res.data.userNeedyVo
            ? res.data.userNeedyVo
            : {};
          this.formData.baseInfo.addUserContactsRo = res.data.userContactsVo
            ? res.data.userContactsVo
            : {};
          this.formData.baseInfo.addUserDetailRo = res.data.userDetailVo
            ? res.data.userDetailVo
            : {};
          this.formData.baseInfo.addUserHouseRo = res.data.userHouseVo
            ? res.data.userHouseVo
            : {};
          this.formData.baseInfo.addUserHealthRo = res.data.userHealthVo
            ? res.data.userHealthVo
            : {};
          this.formData.baseInfo.addUserIncomeRo = res.data.userIncomeVo
            ? res.data.userIncomeVo
            : {};
          this.formData.worker.addArchiveAssessRo = res.data.archiveAssessVo
            ? res.data.archiveAssessVo
            : {};
          this.formData.worker.addArchiveFollowRo = res.data.archiveFollowVo
            ? res.data.archiveFollowVo
            : {};
          this.formData.existingServiceContent =
            res.data.existingServiceContent;
          this.formData.baseInfo.socialServiceContent =
            res.data.socialServiceContent;
          this.formData.baseInfo.useServices =
            res.data.socialServiceContent != "" &&
            res.data.socialServiceContent != null
              ? "1"
              : "0";
          this.formData.baseInfo.mobile = res.data.archiveDetailVo.mobile;
          this.formData.baseInfo.other =
            this.formData.addArchiveUserRo.orgName != "" &&
            this.formData.addArchiveUserRo.orgName != null
              ? true
              : false;
          this.formData.addArchiveUserRo.orgCode =
            res.data.archiveDetailVo.orgCode;
          // 案主问题及需求
          res.data.archiveQuestionConfigVos.map(item => {
            if (item.topType == 1) {
              if (
                !this.formData.addArchiveQuestionRos["个体层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["个体层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["个体层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: item.questionResult
              });
            } else if (item.topType == 2) {
              if (
                !this.formData.addArchiveQuestionRos["家庭层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["家庭层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["家庭层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: item.questionResult
              });
            } else {
              if (
                !this.formData.addArchiveQuestionRos["社会层面"].hasOwnProperty(
                  item.questionType
                )
              ) {
                //判断当前对象是否有这个属性，没有就添加
                this.formData.addArchiveQuestionRos["社会层面"][
                  item.questionType
                ] = [];
              }
              this.formData.addArchiveQuestionRos["社会层面"][
                item.questionType
              ].push({
                questionId: item.questionId,
                questionDesc: item.questionDesc,
                questionResult: item.questionResult
              });
            }
          });
          this.$Message.destroy();
        } else {
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    } else {
      this.$Message.destroy();
    }
  },
  components: {
    AddChangeInfo,
    AddChangeNeed,
    AddChangeWorker,
    AddChangeClassification
  }
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-menu-item-group-title {
  padding-left: 0 !important;
}
.menu {
  position: fixed;
  left: 30px;
  top: 110px;
  width: 210px !important;
  height: calc(100vh - 162px);
  z-index: 1;
}
.rightContent {
  margin-left: 240px;
  padding: 10px;
}
.deptCode {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>
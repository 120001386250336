<template>
  <Form :model="addArchiveUserRo" :inline="true" :label-colon="true">
    <FormItem label="分类" style="width:100%">
      <span slot="label" class="validator">分类:</span>
      <RadioGroup v-model="addArchiveUserRo.difficultType" @on-change="difficultTypeChange">
        <Radio label="1" style="width:100%">低保低收入对象</Radio>
        <Radio label="2" style="width:100%">特困人员</Radio>
        <Radio label="3" style="width:100%">留守老年人</Radio>
        <Radio label="4" style="width:100%">独居孤寡老人</Radio>
        <Radio label="5" style="width:100%">困境儿童</Radio>
        <Radio label="6" style="width:100%">贫困重度残疾人</Radio>
        <Radio label="7">其他</Radio>
        <Input
          :maxlength="20"
          v-model.trim="addArchiveUserRo.difficultTypeDesc"
          style="width: calc(100% - 200px)"
          @on-change="difficultTypeOtherChange"
        ></Input>
      </RadioGroup>
    </FormItem>

    <FormItem label="分级" style="width:100%;margin-top: 16px">
      <RadioGroup v-model="addArchiveUserRo.difficultLevel" vertical>
        <Radio label="1">
          一级
          <span style="color:#aaa">(目前存在明显需求，家庭存在较大危机因素，例如生存保障（衣食住等基本照顾）、遇险期（重病、重残、意外）或其他具有危险的情况。)</span>
        </Radio>
        <Radio label="2">
          二级
          <span style="color:#aaa">(目前没有发现危险，但家庭内部支持薄弱，面临家庭长期维持困境。)</span>
        </Radio>
        <Radio label="3">
          三级
          <span style="color:#aaa">(目前暂时没有明显需求，身心健康方面存在一定程度的问题，但现时能够维持正常生活。)</span>
        </Radio>
        <Radio label="4">
          四级
          <span style="color:#aaa">(目前暂时没有明显需求，身心状况良好。)</span>
        </Radio>
      </RadioGroup>
    </FormItem>
    <FormItem label="档案编号" style="width: 32%">
      <span slot="label" class="validator">档案编号:</span>
      <Input v-model.trim="addArchiveUserRo.archiveNum" :maxlength="20"></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  props: ["addArchiveUserRo", "identification"],
  data() {
    return {};
  },
  methods: {
    //分类
    difficultTypeChange(val) {
      if (val != "7") {
        this.addArchiveUserRo.difficultTypeDesc = "";
      }
    },
    difficultTypeOtherChange() {
      this.addArchiveUserRo.difficultType = "7";
    }
  }
};
</script>

<style scoped lang="less">
.validator {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.ivu-form-item {
  width: 100%;
  margin-bottom: 15px;
  .ivu-input-wrapper {
    width: 200px;
    /deep/input {
      border: 0;
      border-bottom: 1px solid #65656c;
      border-radius: 0;
    }
    /deep/input:hover,
    /deep/input:focus {
      border: 0;
      border-bottom: 1px solid #65656c;
      box-shadow: none;
    }
  }
}
</style>

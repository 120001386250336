<template>
  <Tabs value="个体层面">
    <TabPane :label="i" :name="i" v-for="(k, i) in needData" :key="i">
      <Form :model="needData" :inline="true" :label-colon="true">
        <FormItem :label="index" style="width: 100%;" v-for="(item, index) in k" :key="index">
          <FormItem
            :label="subItem.questionDesc"
            style="width: 100%; padding: 0 26px;"
            v-for="(subItem, subIndex) in item"
            :key="subIndex"
          >
            <Input :maxlength="100" v-model.trim="subItem.questionResult"></Input>
          </FormItem>
        </FormItem>
      </Form>
    </TabPane>
    <TabPane label="现有(请注明服务及开始时间)" name="name4">
      <Form :model="needData" :inline="true" :label-colon="true">
        <FormItem label="服务及开始时间" style="width: 100%">
          <Input
            :maxlength="400"
            v-model.trim="formData.existingServiceContent"
            :rows="10"
            type="textarea"
            show-word-limit
          ></Input>
        </FormItem>
      </Form>
    </TabPane>
  </Tabs>
</template>

<script>
export default {
  props: ["needData", "formData"],
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.ivu-form-item {
  width: 100%;
  margin-bottom: 15px;
  .ivu-input-wrapper {
    width: calc(100% - 200px);
    /deep/input {
      border: 0;
      border-bottom: 1px solid #65656c;
      border-radius: 0;
      width: 100%;
    }
    /deep/input:hover,
    /deep/input:focus {
      border: 0;
      border-bottom: 1px solid #65656c;
      box-shadow: none;
    }
  }
}
</style>
